import { inject, Injectable } from '@angular/core';
import { SidebarResponse } from 'core/capi/models/sidebar-response';
import { SidebarService } from 'core/capi/services';
import { NavigationLinkItem } from 'core/base/model/navigation.model';
import { SidebarMapper } from 'core/data/repository/sidebar/sidebar.mapper';
import { LocalizationService } from 'core/features/localization/services/localization.service';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarRepository {
  private readonly sidebarApi: SidebarService = inject(SidebarService);
  private readonly localization: LocalizationService = inject(LocalizationService);
  private readonly mapper: SidebarMapper = inject(SidebarMapper);

  public sidebarNav$: BehaviorSubject<NavigationLinkItem[] | null> = new BehaviorSubject<
    NavigationLinkItem[] | null
  >(null);

  public getListResponse(): Observable<NavigationLinkItem[]> {
    return this.localization.trackChanges(
      this.sidebarApi
        .getSidebar({
          populate: {
            '0': 'menu,menu.items,menu.items.icon',
          },
        })
        .pipe(
          map((response: SidebarResponse) => this.mapper.mapFromResponse(response)),
          tap(nav => {
            this.sidebarNav$.next(nav);
          }),
        ),
    );
  }
}
