import { tuiCreateTokenFromFactory } from '@taiga-ui/cdk';
const TUI_ICON_RESOLVER = tuiCreateTokenFromFactory(
// TODO: Remove backwards compatibility in 4.0
() => icon => !icon || icon.includes('/') ? icon : `/assets/taiga-ui/icons/${icon.includes('Outline') ? icon : icon.replace('Large', '').concat('Outline')}.svg`);
function tuiIconResolverProvider(useValue) {
  return {
    provide: TUI_ICON_RESOLVER,
    useValue
  };
}

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_ICON_RESOLVER, tuiIconResolverProvider };
