import { inject, Injectable } from '@angular/core';
import { SidebarResponse } from 'core/capi/models/sidebar-response';
import { ResponseMapper } from 'core/base/model/mapper.model';
import { NavigationLinkItem } from 'core/base/model/navigation.model';
import { IconMapper } from 'core/data/common/icon/icon.mapper';

@Injectable({
  providedIn: 'root',
})
export class SidebarMapper implements ResponseMapper<SidebarResponse, NavigationLinkItem[]> {
  private readonly iconMapper: IconMapper = inject(IconMapper);

  public mapFromResponse({ data }: SidebarResponse): NavigationLinkItem[] {
    return (
      data?.attributes?.menu?.items?.map(item => ({
        action: item.link || '',
        text: item.text || '',
        icon: this.iconMapper.mapFrom(item.icon),
      })) || []
    );
  }
}
